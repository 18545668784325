import {
  Shift,
  Signup,
  Event,
  Attendance,
  GetSignupsByQueryParamCommandOutput,
  GetShiftsByEventCommandOutput,
  GetAttendancesByQueryParamCommandOutput,
} from '@amzn/red-velvet-api';
import { getClient } from '../../utils/getClient';
import { QueryClient } from '@tanstack/react-query';
import { callQueryClientAllPagesRedVelvetQuery } from '../allPagesRedVelvetQuery';

type Status =
  | {
      status: { type: 'success' };
    }
  | {
      status: { type: 'error'; reason: string };
    };

type WithStatus<T> = { value?: T } & Status;
const asWithStatus = <T,>(promise: PromiseSettledResult<T>): WithStatus<T> => {
  return promise.status === 'fulfilled'
    ? {
        status: { type: 'success' },
        value: promise.value,
      }
    : {
        status: {
          type: 'error',
          reason: `${promise.reason}`,
        },
      };
};

export const loadAllShiftsForEvent = async (
  client: QueryClient,
  eventId: string
): Promise<Shift[]> => {
  return await callQueryClientAllPagesRedVelvetQuery(client, {
    redVelvetQueryKey: ['getShiftsByEvent', { eventId }],
    converter: (output: GetShiftsByEventCommandOutput) => ({
      items: output.shifts || [],
      next: output.page,
    }),
  });
};

const loadAllUsersignupsForEvent = async (
  client: QueryClient,
  eventId: string,
  alias: string
): Promise<Signup[]> => {
  return await callQueryClientAllPagesRedVelvetQuery(client, {
    redVelvetQueryKey: ['getSignupsByQueryParam', { eventId, alias }],
    converter: (output: GetSignupsByQueryParamCommandOutput) => ({
      items: output.signups || [],
      next: output.page,
    }),
  });
};

const loadAllUserAttendancesForEvent = async (
  client: QueryClient,
  eventId: string,
  userAlias: string
): Promise<Attendance[]> => {
  return await callQueryClientAllPagesRedVelvetQuery(client, {
    redVelvetQueryKey: ['getAttendancesByQueryParam', { eventId, userAlias }],
    converter: (output: GetAttendancesByQueryParamCommandOutput) => ({
      items: output.attendances || [],
      next: output.page,
    }),
  });
};

export type LoadedEvent = {
  eventId: string;
  event: WithStatus<Event>;
  shifts: WithStatus<Shift[]>;
  signups: WithStatus<Signup[]>;
};

export type EventOwnerData =
  | {
      eventId: string;
      event: Event;
      shifts: Shift[];
      signups: Record<string, Signup[]>;
      attendances: Record<string, Attendance[]>;
      attendanceCompletion: Record<string, string>;
    }
  | undefined;

/**
 * batch load all the events specified by id. Requires the logged in user to filter the signups.
 */
export async function loadBetterEvents(
  queryClient: QueryClient,
  ids: string[],
  userId: string
): Promise<LoadedEvent[]> {
  const client = getClient();
  const events: WithStatus<Event>[] = (
    await Promise.allSettled(ids.map((eventId) => client.getEventById({ eventId })))
  ).map(asWithStatus);
  const shiftSets: WithStatus<Shift[]>[] = (
    await Promise.allSettled(ids.map((eventId) => loadAllShiftsForEvent(queryClient, eventId)))
  ).map(asWithStatus);
  const signupSets: WithStatus<Signup[]>[] = (
    await Promise.allSettled(
      ids.map((eventId) => loadAllUsersignupsForEvent(queryClient, eventId, userId))
    )
  ).map(asWithStatus);
  const attendanceSets: WithStatus<Attendance[]>[] = (
    await Promise.allSettled(
      ids.map((eventId) => loadAllUserAttendancesForEvent(queryClient, eventId, userId))
    )
  ).map(asWithStatus);

  return ids.map((id, index) => ({
    eventId: id,
    event: events[index],
    shifts: shiftSets[index],
    signups: signupSets[index],
    attendances: attendanceSets[index],
  }));
}

export async function loadEventUserOwn(eventId: string): Promise<EventOwnerData | undefined> {
  const client = getClient();
  try {
    const managedEventResult = await client.getSingleEventManagedByUser({ eventId });
    if (managedEventResult.eventDetails && managedEventResult.eventDetails.event) {
      const signupsMap: Record<string, Signup[]> = {};
      const attendancesMap: Record<string, Attendance[]> = {};
      const attendanceCompletionMap: Record<string, string> = {};
      managedEventResult.eventDetails.detailedShifts.forEach((detailedShift) => {
        if (detailedShift.shift && detailedShift.shift.shiftId) {
          signupsMap[detailedShift.shift.shiftId] = detailedShift.signups || [];
          attendancesMap[detailedShift.shift.shiftId] = detailedShift.attendees || [];
          attendanceCompletionMap[detailedShift.shift.shiftId] =
            detailedShift.attendanceCompletion || '';
        }
      });
      const result: EventOwnerData = {
        eventId: eventId,
        event: managedEventResult.eventDetails.event,
        shifts: managedEventResult.eventDetails.detailedShifts.map((x) => x.shift),
        signups: signupsMap,
        attendances: attendancesMap,
        attendanceCompletion: attendanceCompletionMap,
      };
      return result;
    }
    return undefined;
  } catch (error) {
    console.log('Failed to get managed event, falling back to standard loading', error);
    return undefined;
  }
}
