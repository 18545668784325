import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  AppLayout,
  ContentLayout,
  Header,
  Tabs,
  BreadcrumbGroup,
} from '@amzn/awsui-components-react';
import { ActivitySidebar } from './sideNav';
import { getClient } from '../../utils/getClient';
import {
  BulkCreateAttendanceCommandInput,
  BulkCreateAttendanceCommandOutput,
  CreateAttendanceRequest,
  BulkUpdateAttendanceCommandInput,
  BulkUpdateAttendanceCommandOutput,
  BulkUpdateAttendances,
  GetEventsManagedByUserResponse,
  GetEventDetailsManagedByUserResponse,
} from '@amzn/red-velvet-api';

const dummyAttendanceRequests: CreateAttendanceRequest[] = [
  {
    alias: 'phanmi',
    userId: '109905966',
    eventId: '24057',
    shiftId: '111',
    minutes: 120,
    attendanceStatus: 'ATTENDED',
  },
  {
    alias: 'jrtrre',
    userId: '103066782',
    eventId: '24057',
    shiftId: '111',
    minutes: 90,
    attendanceStatus: 'ATTENDED',
  },
  {
    alias: 'reqian',
    userId: '102332167',
    eventId: '24057',
    shiftId: '111',
    minutes: 180,
    attendanceStatus: 'ATTENDED',
  },
];

const dummyAttendanceUpdateRequests: BulkUpdateAttendances[] = [
  {
    attendanceId: 'eventId-24057-shiftId-111-userAlias-azalea',
    minutes: 0,
    attendanceStatus: 'ATTENDED',
  },
  {
    attendanceId: 'eventId-24057-shiftId-111-userAlias-phanmi',
    minutes: 0,
    attendanceStatus: 'DID_NOT_ATTEND',
  },
  {
    attendanceId: 'eventId-24057-shiftId-111-userAlias-jrtrre',
    minutes: 120,
    attendanceStatus: 'ATTENDED',
  },
];

const dummyBulkCreateAttendanceInput: BulkCreateAttendanceCommandInput = {
  attendanceRequests: dummyAttendanceRequests,
};

const dummyBulkUpdateAttendanceInput: BulkUpdateAttendanceCommandInput = {
  updateRequests: dummyAttendanceUpdateRequests,
};

export function CoordinatorPage() {
  const { t } = useTranslation(['activity', 'translation']);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const urlFeatures = searchParams.get('features');
  const coordinatorActivityUrl = `/coordinator-activity${urlFeatures ? '?features=' + urlFeatures : ''}`;
  const [bulkCreateResults, setBulkCreateResults] = useState<BulkCreateAttendanceCommandOutput>();
  const [bulkUpdateResults, setBulkUpdateResults] = useState<BulkUpdateAttendanceCommandOutput>();
  const [getEventsManagedByUser, setGetEventsManagedByUser] =
    useState<GetEventsManagedByUserResponse>();
  const [getEventDetailsManagedByUser, setGetEventDetailsManagedByUser] =
    useState<GetEventDetailsManagedByUserResponse>();

  const handleBulkCreateAttendance = async () => {
    try {
      const results = await getClient().bulkCreateAttendance(dummyBulkCreateAttendanceInput);
      setBulkCreateResults(results);
    } catch (error) {
      console.error('Error calling bulkCreateAttendance:', error);
    }
  };

  const handleBulkUpdateAttendance = async () => {
    try {
      const results = await getClient().bulkUpdateAttendance(dummyBulkUpdateAttendanceInput);
      setBulkUpdateResults(results);
    } catch (error) {
      console.error('Error calling bulkUpdateAttendance:', error);
    }
  };

  const handleEventsByOwner = async () => {
    try {
      const events = await getClient().getEventsManagedByUser({});
      setGetEventsManagedByUser(events);
    } catch (error) {
      console.error('Error calling handleEventsByOwner:', error);
    }
  };

  const handleEventDetailsByOwner = async () => {
    try {
      const events = await getClient().getEventDetailsManagedByUser({});
      setGetEventDetailsManagedByUser(events);
    } catch (error) {
      console.error('Error calling handleEventDetailsByOwner:', error);
    }
  };

  return (
    <AppLayout
      footerSelector=".footer"
      navigationHide={false}
      navigation={<ActivitySidebar baseUrl={coordinatorActivityUrl} />}
      toolsHide={true}
      breadcrumbs={
        <div className="breadcrumbs">
          <BreadcrumbGroup
            items={[
              { text: t('breadcrumbs.home', { ns: 'translation' }), href: '/' },
              { text: t('breadcrumbs.coordinatorActivity'), href: '#' },
            ]}
            onFollow={(event) => {
              navigate(event.detail.href);
              event.preventDefault();
            }}
          />
        </div>
      }
      content={
        <div className="page-content">
          <ContentLayout
            header={
              <div className="page-content-header">
                <Header variant="h1">{t('pageTitle.coordinatorActivity')}</Header>
              </div>
            }
          >
            <button onClick={handleBulkCreateAttendance}>Call bulkCreateAttendance</button>
            {bulkCreateResults && (
              <div>
                <h2>Bulk Create Attendance Results:</h2>
                <ul>
                  {bulkCreateResults.attendanceIds.map((result, index) => (
                    <li key={index}>{result}</li>
                  ))}
                </ul>
              </div>
            )}

            <button onClick={handleBulkUpdateAttendance}>Call bulkUpdateAttendance</button>
            {bulkUpdateResults && (
              <div>
                <h2>Bulk Update Attendance Results:</h2>
                <ul>
                  {bulkUpdateResults.attendanceIds.map((result, index) => (
                    <li key={index}>{result}</li>
                  ))}
                </ul>
              </div>
            )}

            <button onClick={handleEventsByOwner}>Call handleEventsByOwner</button>
            {getEventsManagedByUser && (
              <div>
                <h2>Events under my name:</h2>
                <ul>
                  {getEventsManagedByUser.events.map((event, index) => (
                    <li key={index}>
                      {event.title} - {event.eventId}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <button onClick={handleEventDetailsByOwner}>Call handleEventDetailsByOwner</button>
            {getEventDetailsManagedByUser && (
              <div>
                <h2>Events and shifts under my name:</h2>
                <ul>
                  {getEventDetailsManagedByUser.eventDetails.map((eventDetails, index) => (
                    <li key={index}>
                      <h3>
                        Event Name: {eventDetails.event.title} - {eventDetails.event.eventId}
                      </h3>
                      {eventDetails.detailedShifts.map((detailedShifts, idx) => (
                        <li key={idx}>
                          <h4>
                            {detailedShifts.shift.shiftId} - Shift name: "
                            {detailedShifts.shift.name}"
                          </h4>
                          <p>Total of attendees: </p>
                          {detailedShifts.attendees &&
                            detailedShifts.attendees.map((attendance, i) => (
                              <li key={i}>
                                <p>
                                  {attendance.attendanceStatus} - {attendance.alias}
                                </p>
                              </li>
                            ))}
                          <p>Total of signups: </p>
                          {detailedShifts.signups &&
                            detailedShifts.signups.map((signup, i) => (
                              <li key={i}>
                                <p>SparkId: {signup.userId}</p>
                              </li>
                            ))}
                          <p>Total of guests: {detailedShifts.shift.totalNumberOfGuests}</p>
                          <p>Track attendance completion status: {detailedShifts.attendanceCompletion}</p>
                        </li>
                      ))}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </ContentLayout>
          {t('incomingFeature')}
        </div>
      }
      contentType="cards"
    />
  );
}
